import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { basePageWrap } from '../BasePage';
import StreamField from 'Components/StreamField';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './PageHome.nordr.module.scss';
import stylesFolkhem from './PageHome.folkhem.module.scss';

const HeroStart = dynamic(() => import('../../components/HeroStart'));
const FindYourEstate = dynamic(() => import('../../components/FindYourEstate'));
const TextColumns = dynamic(() => import('../../components/TextColumns'));

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class PageHome extends React.PureComponent {
    render() {
        const { items, findYourEstate, textColumns, hero, seo } = this.props;

        const theme = this.context;
        const styles = getStyles(theme);

        return (
            <div className={styles["PageHome"]}>
                <h1 className="sr-only">{seo.title}</h1>

                {hero && (
                    <div className={styles["PageHome__Hero"]}>
                        <HeroStart {...hero} />
                    </div>
                )}

                {findYourEstate && (
                    <div className={styles["PageHome__FindYourEstate"]}>
                        <FindYourEstate {...findYourEstate} />
                    </div>
                )}

                <div className={styles["PageHome__StreamField"]}>
                    <StreamField items={items} pageType={'Home'} />
                </div>

                {textColumns && (
                    <div className={styles["PageHome__TextColumns"]}>
                        <TextColumns {...textColumns} />
                    </div>
                )}
            </div>
        );
    }
}

PageHome.propTypes = {
    items: PropTypes.array,
    findYourEstate: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    textColumns: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

PageHome.defaultProps = {
    items: [],
    findYourEstate: null,
};

PageHome.contextType = ThemeContext;

export default basePageWrap(PageHome);
